import {
  GET_PDFTemplate,
  GET_PDFTemplateDetail,
  DELETE_PDFTEMPLATE,
  SWITCHACTIVE_PDFTEMPLATE,
  SET_CLEAR,
  UNCHECKED_ALL,
  GET_PDFTemplateAssigment
} from "./../ActionTypes";
import API from "./../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage, message } from "actions/message";

export const getPDFTemplate = () => dispatch => {
  const url = "dme_portal/signature_documents";
  dispatch(showLoader());
  API()
    .get(url)
    .then(res => {
      dispatch(hideLoader());
      if (res && res.data && res.data.success) {
        if (res.data.signature_documents) {
          let reponseData = res.data.signature_documents;
          reponseData.map(rdata => {
            rdata.is_delete = true;
            if (rdata.patients && rdata.patients.length > 0) {
              rdata.patients.map(edata => {
                if (edata.download_url && edata.download_url.length > 0) {
                  rdata.is_delete = false;
                }
              });
            }
          });
          dispatch(setTemplates(reponseData));
        }
      } else {
        // dispatch(errorMessage(res.data.message));
        return dispatch({
          type: GET_PDFTemplate,
          payload: []
        });
      }
    })
    .catch(err => {
      // const responseErrorMessage =  err.response.data.message;
      dispatch(hideLoader());
      dispatch(errorMessage(err.message));
      return dispatch({
        type: GET_PDFTemplate,
        payload: []
      });
    });
};

export const setTemplates = data => dispatch => {
  return dispatch({
    type: GET_PDFTemplate,
    payload: data
  });
};

export const getPDFTemplateDetail = (id, isMessageDisplay) => dispatch => {
  const url = "dme_portal/signature_documents";
  API()
    .get(url)
    .then(res => {
      if (res && res.data && res.data.success) {
        if (res.data.signature_documents) {
          if (isMessageDisplay) {
            dispatch(message("Document detail fetch successfully."));
          }
          dispatch(setTemplates(res.data.signature_documents));
          return dispatch({
            type: GET_PDFTemplateDetail,
            payload: id
          });
        }
      } else {
        dispatch(errorMessage(res.data.message));
        return dispatch({
          type: GET_PDFTemplate,
          payload: []
        });
      }
    })
    .catch(err => {
      console.log("error", err.response);
      const responseErrorMessage = err.response.data.message;
      dispatch(errorMessage(responseErrorMessage));
      return dispatch({
        type: GET_PDFTemplate,
        payload: []
      });
    });
};

export const addPatientToTemplate = (tid, patients) => dispatch => {
  const url = "dme_portal/signature_documents/" + tid + "/add_patients";
  const createObj = {
    patients: patients
  };
  API()
    .post(url, createObj)
    .then(res => {
      if (res && res.data && res.data.success) {
        dispatch(message(res.data.message));
        dispatch(setClearStatus(true));
        dispatch(getPDFTemplateDetail(tid, false));
        return dispatch({
          type: GET_PDFTemplateAssigment,
          payload: res.data.SignatureIds
        });
      } else {
        dispatch(errorMessage(res.data.message));
      }
    })
    .catch(err => {
      console.log("error", err.response);
      const responseErrorMessage = err.response.data.message;
      dispatch(errorMessage(responseErrorMessage));
    });
};

export const deletePDFTemplate = tid => dispatch => {
  const url = "dme_portal/signature_document/" + tid;
  dispatch(showLoader());
  API()
    .delete(url)
    .then(res => {
      dispatch(hideLoader());
      if (res && res.data && res.data.success) {
        dispatch(message(res.data.message));
        return dispatch({
          type: DELETE_PDFTEMPLATE,
          payload: tid
        });
      } else {
        dispatch(errorMessage(res.data.message));
      }
    })
    .catch(err => {
      console.log("error", err.response);
      const responseErrorMessage = err.response.data.message;
      dispatch(hideLoader());
      dispatch(errorMessage(responseErrorMessage));
    });
};

export const switchActivePDFTemplate = (tid, active) => dispatch => {
  const url = "dme_portal/signature_document/" + tid + "/active/" + active;
  dispatch(showLoader());
  API()
    .put(url)
    .then(res => {
      dispatch(hideLoader());
      if (res && res.data && res.data.success) {
        dispatch(message(res.data.message));
        return dispatch({
          type: SWITCHACTIVE_PDFTEMPLATE,
          payload: { tid, active }
        });
      } else {
        dispatch(errorMessage(res.data.message));
      }
    })
    .catch(err => {
      console.log("error", err.response);
      const responseErrorMessage = err.response.data.message;
      dispatch(hideLoader());
      dispatch(errorMessage(responseErrorMessage));
    });
};

// export const resendPDFTemplate = (
//   signature_document_id,
//   patients
// ) => dispatch => {
//   return API()
//     .put("dme_portal/signature_documents", {
//       signature_document_id,
//       patients: patients.map(({ id }) => id)
//     })
//     .then(res => {
//       if (res && res.data && res.data.success) {
//         dispatch(message(res.data.message));
//       } else {
//         dispatch(errorMessage(res.data.message));
//       }
//     })
//     .catch(err => {
//       const responseErrorMessage = err.response.data.message;
//       dispatch(errorMessage(responseErrorMessage));
//     });
// };

export const setClearStatus = status => dispatch => {
  return dispatch({
    type: SET_CLEAR,
    payload: status
  });
};

export const uncheckedAll = status => dispatch => {
  return dispatch({
    type: UNCHECKED_ALL,
    payload: status
  });
};
