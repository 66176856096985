import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IoEdit from "react-icons/lib/io/edit";
import IoEye from "react-icons/lib/io/eye";
import IoIosCopy from "react-icons/lib/io/ios-copy";
import {
  getPDFTemplate,
  deletePDFTemplate,
  switchActivePDFTemplate
} from "components/dd_newModules/Redux/Actions/pdftemplate";
import { setPdfWindowOpenStatus } from "actions/app-layout";
import moment from "moment";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { selectors } from "reducers";
import { PHP_Domain } from "environment";
import "./style.scss";
import { PureModal } from "components/ui";
import AssignedPatients from "./assigned-patients";

const ManagePdfs = ({
  getPDFTemplate,
  switchActivePDFTemplate,
  PDFTemplates,
  setPdfWindowOpenStatus,
  user,
  isLoading
}) => {
  const { rows, query, queryDispatcher, count } = useLocalQuery(
    PDFTemplates ? PDFTemplates : [],
    {
      sort: { by: "", direction: "asc" },
      filters: { active: [true] },
      pagination: { page: 1, per: 100 }
    }
  );
  const [pdfWindowOpen, setPdfWindowOpen] = React.useState(false);

  React.useEffect(() => {
    getPDFTemplate();
  }, []);

  function openPDFWindow() {
    document.body.style.pointerEvents = "none";
    document.body.style.cursor = "wait";
    document.body.style.filter = "brightness(80%)";
    setPdfWindowOpen(true);
    // const encoded_token = window.btoa(user.authentication_token);
    var new_window = window.open(
      `${PHP_Domain}/create-template?token=${user.authentication_token}`,
      "_blank",
      "height=" + screen.height + ", width=" + screen.width
    );
    setPdfWindowOpenStatus(true);
    var timer = setInterval(() => {
      if (new_window.closed) {
        clearInterval(timer);
        onUnload();
        setPdfWindowOpenStatus(false);
      }
    }, 1000);
  }

  function onUnload() {
    getPDFTemplate(false);
    setPdfWindowOpen(false);
    document.body.style.pointerEvents = "inherit";
    document.body.style.cursor = "default";
    document.body.style.filter = "brightness(100%)";
  }

  function previewPDFWindow(templateId) {
    document.body.style.pointerEvents = "none";
    document.body.style.cursor = "wait";
    document.body.style.filter = "brightness(80%)";
    setPdfWindowOpen(true);

    var new_window = window.open(
      `${PHP_Domain}/template-preview?template_id=${templateId}&token=${user.authentication_token}`,
      "_blank",
      "height=" + screen.height + ", width=" + screen.width
    );
    setPdfWindowOpenStatus(true);
    var timer = setInterval(() => {
      if (new_window.closed) {
        clearInterval(timer);
        onUnload();
        setPdfWindowOpenStatus(false);
      }
    }, 1000);
  }

  function editPDFWindow(templateId) {
    document.body.style.pointerEvents = "none";
    document.body.style.cursor = "wait";
    document.body.style.filter = "brightness(80%)";
    setPdfWindowOpen(true);

    var new_window = window.open(
      `${PHP_Domain}/template-editor?template_id=${templateId}&token=${user.authentication_token}`,
      "_blank",
      "height=" + screen.height + ", width=" + screen.width
    );
    setPdfWindowOpenStatus(true);
    var timer = setInterval(() => {
      if (new_window.closed) {
        clearInterval(timer);
        onUnload();
        setPdfWindowOpenStatus(false);
      }
    }, 1000);
  }

  function copyPDFWindow(templateId) {
    document.body.style.pointerEvents = "none";
    document.body.style.cursor = "wait";
    document.body.style.filter = "brightness(80%)";
    setPdfWindowOpen(true);

    var new_window = window.open(
      `${PHP_Domain}/template-copy?template_id=${templateId}&token=${user.authentication_token}`,
      "_blank",
      "height=" + screen.height + ", width=" + screen.width
    );
    setPdfWindowOpenStatus(true);
    var timer = setInterval(() => {
      if (new_window.closed) {
        clearInterval(timer);
        onUnload();
        setPdfWindowOpenStatus(false);
      }
    }, 1000);
  }

  const [selectedDocument, setSelectedDocument] = useState(null)

  const onAssignedPatientsSelected = (documentId) => {
    document.getElementById("assignedPatients").click();
    setSelectedDocument(documentId);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "relative"
      }}
    >
      <Table
        containerClass="query-table manage-pdf-table"
        isFetching={isLoading}
        rowKey="id"
        TableRow={row => (
          <tr>
            <td
              style={
                row.active ? undefined : { textDecoration: "line-through" }
              }
            >
              {row.document_title}
            </td>
            <td>
              {moment(moment.tz(row.date_of_delivery, "US/Central")).format(
                "M/D/YYYY"
              )}
            </td>
            <td>
              <span
                onClick={() => onAssignedPatientsSelected(row.id)}
                style={{ color: "#4281A6", cursor: "pointer" }}
              >
                {row.patients.length}
              </span>
            </td>
            <td>{row.reminder_days}</td>
            <td>
              <div>
                <input
                  type="checkbox"
                  checked={row.active}
                  onChange={e => {
                    switchActivePDFTemplate(row.id, e.target.checked);
                  }}
                />
              </div>
            </td>
            <td>
              <div>
                <IoEye
                  style={{
                    color: "#007bff",
                    fontSize: "1.5em",
                    cursor: "pointer"
                  }}
                  onClick={() => previewPDFWindow(row.html_template_id)}
                />{" "}
              </div>
            </td>
            <td>
              <div>
                {row.active ? (
                  <IoEdit
                    style={{
                      color: "#28a745",
                      fontSize: "1.5em",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      editPDFWindow(row.html_template_id);
                    }}
                  />
                ) : null}
              </div>
            </td>
            <td>
              <div>
                <IoIosCopy
                  style={{
                    color: "#007bff",
                    fontSize: "1.5em",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    copyPDFWindow(row.html_template_id);
                  }}
                />
              </div>
            </td>
          </tr>
        )}
        count={count}
        columns={[
          {
            header: "Title",
            field: "document_title"
          },
          {
            header: "Delivery Date",
            field: "date_of_delivery"
          },
          {
            header: "Assigned Patients",
            field: "assignedPatients"
          },
          {
            header: "Reminder Days",
            field: "reminder_days"
          },
          {
            header: "Active",
            field: "active",
            filters: [
              { text: "Active", value: true },
              { text: "Inactive", value: false }
            ],
            title: "Template Active"
          },
          {
            header: "View Template",
            field: "viewTemplate"
          },
          {
            header: "Edit Template",
            field: "editTemplate"
          },
          {
            header: "Copy Template",
            field: "copyTemplate"
          }
        ]}
        rows={rows}
        query={query}
        queryDispatcher={queryDispatcher}
      />
      <button
        disabled={pdfWindowOpen}
        type="button"
        onClick={() => {
          openPDFWindow();
        }}
        style={{
          margin: "10px 0px",
          marginTop: 25,
          radius: 3,
          borderRadius: 3,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          display: "flex"
        }}
      >
        Create New PDF Template
      </button>
      <PureModal
        windowProps={{ large: true, modalZIndex: 6, modalBehindModal: true }}
        renderTrigger={({ openModal }) => (<div id="assignedPatients" onClick={openModal}></div>)}
        renderContent={({ closeModal }) => (
          <AssignedPatients
            closeModal={closeModal}
            selectedDocumentId={selectedDocument}
          />
        )}
      />
    </div>
  );
};

export default connect(
  state => ({
    PDFTemplates: state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplate,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader,
    user: selectors.getUser(state)
  }),
  {
    getPDFTemplate,
    deletePDFTemplate,
    setPdfWindowOpenStatus,
    switchActivePDFTemplate
  }
)(ManagePdfs);

ManagePdfs.propTypes = {
  isLoading: PropTypes.bool,
  PDFTemplates: PropTypes.arrayOf(PropTypes.object),
  getPDFTemplate: PropTypes.func.isRequired,
  deletePDFTemplate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setPdfWindowOpenStatus: PropTypes.func.isRequired,
  switchActivePDFTemplate: PropTypes.func.isRequired
};
